<template>
  <div class="sync-data-page">
    <h1>Data Sync</h1>

    <div class="sync-options">
      <!-- Sync buttons -->
      <div class="sync-card" @click="syncBranch">
        <i class="fas fa-building"></i>
        <div class="sync-info">
          <h2>Sync Branch</h2>
          <p>Synchronize branch data</p>
        </div>
      </div>

      <div class="sync-card" @click="syncPackages">
        <i class="fas fa-box"></i>
        <div class="sync-info">
          <h2>Sync Packages</h2>
          <p>Synchronize package data</p>
        </div>
      </div>

      <div class="sync-card" @click="syncCollectedPackages">
        <i class="fas fa-truck"></i>
        <div class="sync-info">
          <h2>Sync Collected</h2>
          <p>Synchronize collected packages</p>
        </div>
      </div>      

      <div class="sync-card" @click="syncEnteredPackages">
        <i class="fas fa-entered"></i>
        <div class="sync-info">          
          <h2>Sync Entered Packages</h2>
          <p>Synchronize Packages in Entered Status</p>
        </div>
      </div>

      <div class="sync-card" @click="syncCustomers">
        <i class="fas fa-users"></i>
        <div class="sync-info">
          <h2>Sync Customers</h2>
          <p>Synchronize customer data</p>
        </div>
      </div>

      <div class="sync-card" @click="syncInvoices">
        <i class="fas fa-users"></i>
        <div class="sync-invoice">
          <h2>Sync Invoices</h2>
          <p>Synchronize invoices uploaded by customers</p>
        </div>
      </div>
      
      <div class="sync-card" @click="syncNoInvoices">
        <i class="fas fa-users"></i>
        <div class="sync-no-invoice">
          <h2>Sync No Invoices</h2>
          <p>Push packages to sheets without invoices</p>
        </div>
      </div>

    </div>

    <div class="run-history">
      <div class="run-history-header">
        <h2>Run History</h2>
        <button class="refresh-button" @click="refreshHistory" :disabled="isRefreshing">
          {{ isRefreshing ? 'Refreshing...' : 'Refresh' }}
        </button>
        <p v-if="lastRefreshed" class="last-refreshed">Last refreshed: {{ lastRefreshed }}</p>
      </div>

      <div class="pagination top-pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Action</th>
            <th>Status</th>
            <th>Description</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="run in paginatedRunHistory" :key="run.id">
            <td>{{ run.action }}</td>
            <td :class="{'status-success': run.success, 'status-fail': !run.success}">
              {{ run.success ? 'Success' : 'Failed' }}
            </td>
            <td>{{ run.description }}</td>
            <td>{{ formatDate(run.timestamp) }}</td>
            <td>{{ formatTime(run.timestamp) }}</td>
          </tr>
        </tbody>
      </table>

      <div class="pagination bottom-pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SyncData',
  data() {
    return {
      isRefreshing: false,
      lastRefreshed: null, // To store the last refreshed time
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState({
      runHistory: state => state.runHistory || []
    }),
    paginatedRunHistory() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.runHistory.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.runHistory.length / this.pageSize);
    }
  },
  methods: {
    ...mapActions(['syncBranch', 'syncPackages', 'syncCollectedPackages', 'syncCustomers', 'syncEnteredPackages', 'fetchRunHistory', 'syncInvoices', 'syncNoInvoices']),
    async refreshHistory() {
      this.isRefreshing = true;
      try {
        await this.fetchRunHistory();
        this.lastRefreshed = new Date().toLocaleTimeString(); // Set last refreshed time
      } catch (error) {
        console.error('Error fetching run history:', error);
      } finally {
        this.isRefreshing = false;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    formatDate(dateString) {
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    formatTime(timeString) {
      return new Date(timeString).toLocaleTimeString('en-US');
    }
  },
  async mounted() {
    await this.refreshHistory();
  }
};
</script>

<style scoped>
.sync-data-page {
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.sync-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sync-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sync-card:hover {
  background-color: #f0f0f0;
}

.sync-card i {
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.sync-info h2 {
  font-size: 18px;
  margin: 10px 0;
}

.sync-info p {
  font-size: 14px;
  color: #777;
}

.run-history {
  margin-top: 40px;
}

.run-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.run-history table {
  width: 100%;
  border-collapse: collapse;
}

.run-history th,
.run-history td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.run-history th {
  background-color: var(--primary-color);
  color: white;
}

.run-history .last-refreshed {
  font-size: 12px;
  color: #777;
}

/* Alternating row colors */
.run-history table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.run-history table tbody tr:nth-child(even) {
  background-color: #fff;
}

.status-success {
  color: green;
}

.status-fail {
  color: red;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.top-pagination {
  margin-bottom: 20px;
}

.bottom-pagination {
  margin-top: 20px;
}

.refresh-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.refresh-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.refresh-button:hover:enabled {
  background-color: var(--hover-color);
}
</style>
