import { createStore } from 'vuex';
import axios from '@/plugins/axios';
import router from '@/router';
import { useToast } from "vue-toastification";

const toast = useToast();

export default createStore({
  state: {
    token: localStorage.getItem('token') || null, // Initialize token from localStorage
    user: null,
    expirationTime: null,
    shipments: [],
    uncollectedShipments: [],
    shipmentsToBePaid: [],
    recentShipments: [],
    packages: [],
    package: null,
    loading: false,
    customerSearchResults: [],
    runHistory: [],
    pagination: {
      total: 0,
      page: 1,
      pageSize: 10,
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token); // Save token to localStorage
    },
    clearAuthData(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token'); // Remove token from localStorage
    },
    setExpirationTime(state, ttl) {
      const currentTime = Date.now();
      state.expirationTime = currentTime + ttl * 1000;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setShipments(state, shipments) {
      state.shipments = shipments;
    },
    setUncollectedShipments(state, shipments) {
      state.uncollectedShipments = shipments || [];
    },
    setShipmentsToBePaid(state, shipments) {
      state.shipmentsToBePaid = shipments || [];
    },
    setRecentShipments(state, { shipments, total, page, pageSize }) {
      state.recentShipments = shipments || [];
      state.pagination.total = total;
      state.pagination.page = page;
      state.pagination.pageSize = pageSize;
    },
    setPackages(state, packages) {
      state.packages = packages || [];
    },
    setPackage(state, packageData) {
      state.package = packageData || [];
    },
    setPackagesRequiringAttention(state, { shipments, total, page, pageSize }) {
      state.recentShipments = shipments || [];
      state.pagination.total = total;
      state.pagination.page = page;
      state.pagination.pageSize = pageSize;  // Ensure this updates correctly
    },
    setCustomerSearchResults(state, customers) {
      state.customerSearchResults = customers || [];
    },
    setRunHistory(state, history) {
      state.runHistory = history || [];
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post('/auth/login', { username, password });
        const { token, userId, ttl } = response.data;
        commit('setToken', token);
        commit('setUser', { id: userId, username });
        commit('setExpirationTime', ttl);
        router.push('/');
      } catch (error) {
        console.error('Login error:', error);
      }
    },
    autoLogin({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        // Optionally, you can verify the token or get user details from an API
        commit('setToken', token);
      }
    },
    logout({ commit }) {
      commit('clearAuthData');
      router.push('/login');
    },
    async fetchAllShipments({ commit }) {
      try {
        const response = await axios.get('/shipments/');
        commit('setShipments', response.data.shipments);
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    },
    async fetchUncollectedShipments({ commit, dispatch }) {
      try {
        const response = await axios.get('/shipments/uncollected');
        commit('setUncollectedShipments', response.data.shipments);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async fetchShipmentsToBePaid({ commit, dispatch }) {
      try {
        const response = await axios.get('/shipments/unpaid');
        commit('setShipmentsToBePaid', response.data.shipments);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async fetchPackagesByShipmentId({ commit, dispatch }, shipmentId) {
      try {
        const response = await axios.get(`/packages/byShipmentId/${shipmentId}`);
        commit('setPackages', response.data);
        return response.data;
      } catch (error) {
        dispatch('handleApiError', error);
        throw error;
      }
    },
    async fetchPackageById({ commit, dispatch }, packageId) {
      try {
        const response = await axios.get(`/packages/${packageId}`);
        commit('setPackage', response.data);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async fetchPackagesRequiringAttention({ commit, state }, { page = 1, pageSize = 10 } = {}) {
      commit('setLoading', true);
      try {
        const response = await axios.get(`/packages/requiring/attention`, {
          params: { pageSize, page },
          headers: { Authorization: `Bearer ${state.token}` },
        });

        if (response.data && response.data.shipments) {
          commit('setPackagesRequiringAttention', {
            shipments: response.data.shipments,
            total: response.data.total,
            page: parseInt(response.data.page, 10),
            pageSize: parseInt(response.data.pageSize, 10),
          });
        } else {
          console.error('Unexpected API response:', response);
          commit('setPackagesRequiringAttention', { shipments: [], total: 0, page: 1, pageSize });
        }
      } catch (error) {
        console.error('Error fetching packages requiring attention:', error);
        toast.error("Failed to fetch packages requiring attention.");
      } finally {
        commit('setLoading', false);
      }
    },
    async searchPackages({ commit, dispatch }, query) {
      try {
        const response = await axios.get(`/packages/search/${query}`);
        commit('setRecentShipments', {
          shipments: response.data.shipments,
          total: response.data.total,
          page: response.data.page,
          pageSize: parseInt(response.data.pageSize, 10),
        });
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async updatePackage({ commit, dispatch }, packageData) {
      try {
        const response = await axios.put(`/packages/${packageData.id}`, packageData);
        commit('setPackage', response.data);
        toast.success("Package updated successfully");
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async updatePackagesByShipmentId({ commit, dispatch }, { shipmentId, updates }) {
      commit('setLoading', true);
      try {
        await axios.put(`/packages/bulk/updateByShipmentId/${shipmentId}`, { updates });
        const updatedResponse = await axios.get(`/packages/byShipmentId/${shipmentId}`);
        commit('setPackages', updatedResponse.data);
        toast.success("Packages updated successfully");
      } catch (error) {
        dispatch('handleApiError', error);
        toast.error("Failed to update packages");
      } finally {
        commit('setLoading', false);
      }
    },
    async bulkUpdatePackages({ dispatch, commit }, { updates }) {
      commit('setLoading', true);
      try {
        await axios.put(`/packages/bulk/update`, { updates });
        toast.success("Packages updated successfully");
      } catch (error) {
        dispatch('handleApiError', error);
        toast.error("Failed to update packages");
      } finally {
        commit('setLoading', false);
      }
    },
    async updateShipment({ commit, dispatch }, shipment) {
      commit('setLoading', true);
      try {
        await axios.put(`/shipments/${shipment.id}`, shipment);
        const updatedResponse = await axios.get('/shipments/uncollected');
        commit('setUncollectedShipments', updatedResponse.data);
        toast.success("Shipment updated successfully");
      } catch (error) {
        dispatch('handleApiError', error);
        toast.error("Failed to update shipment");
      } finally {
        commit('setLoading', false);
      }
    },
    async searchCustomer({ commit }, query) {
      try {
        const response = await axios.get(`/customers/search/${query}`);
        commit('setCustomerSearchResults', response.data);
        return response.data;
      } catch (error) {
        console.error('Error searching customers:', error);
      }
    },
    async syncBranch({ dispatch }) {
      try {
        const response = await axios.post('/branches/sync/');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncPackages({ dispatch }) {
      try {
        const response = await axios.post('/packages/sync-sheet/');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncCollectedPackages({ dispatch }) {
      try {
        const response = await axios.post('/packages/sync-collected/');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncEnteredPackages({ dispatch }) {
      try {
        const response = await axios.post('/packages/sync-entered/');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncCustomers({ dispatch }) {
      try {
        const response = await axios.post('/customers/sync/oxcustomer');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncNoInvoices({ dispatch }) {
      try {
        const response = await axios.post('/invoices/no-invoice/push-to-sheet');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async syncInvoices({ dispatch }) {
      try {
        const response = await axios.post('/invoices/sync-sheet');
        toast.success(response.data.message);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    async fetchRunHistory({ commit, dispatch }) {
      try {
        const response = await axios.get('/status/');
        commit('setRunHistory', response.data);
      } catch (error) {
        dispatch('handleApiError', error);
      }
    },
    handleApiError({ commit }, error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        commit('clearAuthData');
        router.push('/login');
        toast.error("Session expired. Please log in again.");
      } else {
        console.error('API error:', error);
      }
    },
  },
  getters: {
    // isAuthenticated(state) {
    //   const token = state.token;
    //   const currentTime = Date.now();
    //   return !!token && currentTime < state.expirationTime;
    // },
    isAuthenticated(state) {
      return !!state.token;
    },
    isLoading(state) {
      return state.loading;
    },
    customerSearchResults(state) {
      return state.customerSearchResults;
    },
    runHistory(state) {
      return state.runHistory;
    },
    pagination(state) {
      return state.pagination;
    },
  },
});
